import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer style={{ textAlign: 'center', fontStyle: 'italic', padding: '1em 0' }}>
      <p>© {currentYear} The Hanky Panks</p>
    </footer>
  );
};

export default Footer;
