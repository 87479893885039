import NextShow from "./NextShow";
import YouTubePlayer from "./YouTubePlayer";

const Home = () => {
  return (
    <>
      <NextShow />
      <p>
        <b>Let the games begin!</b>
      </p>
      <p>
        More improvements are coming to this site over the coming days and
        weeks, but hey, we finally have one!
      </p>
      <p>
        In the meantime, click the "Shows" link above to find out where we're
        playing next! And don't forget to{" "}
        <a href="http://facebook.com/thehankypanks">follow us on Facebook</a>.
      </p>
      <YouTubePlayer />
      <div className="band-members">
        Brian Cafferky: vocals, harmonica
        <br />
        Kevin Cooper: vocals, guitar, keyboard
        <br />
        Richard Stonely: vocals, bass
        <br />
        Walter Piper: drums
      </div>
    </>
  );
};

export default Home;
