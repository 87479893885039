const Contact = () => {
    const emailUser = "booking";
    const emailDomain = "thehankypanks.com";
    const email = `${emailUser}@${emailDomain}`;
  
    return (
      <div style={{ textAlign: "left", fontSize: "1.25rem" }}>
        For booking inquiries, you can reach us via:
        <ul>
          <li>
            Email: <b>{emailUser} [at] {emailDomain}</b>
          </li>
          <li>
            Or message us from{" "}
            <a href="http://facebook.com/thehankypanks">our Facebook page</a>
          </li>
        </ul>
      </div>
    );
  };
  
  export default Contact;
  