import React, { useEffect, useState } from 'react';

const Shows = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('https://oksure.com/nodejs-googlecal/api/events')
      .then(response => response.json())
      .then(data => {
        const filteredEvents = data.filter(event => event.summary[0] === 'The Hanky Panks');
        setEvents(filteredEvents);
        setIsLoading(false);
      })
      .catch(error => console.error('Error fetching events:', error));
  }, []);

  if (isLoading) {
    return 'Loading shows; please wait...';
  }

  return (
    <table className='shows-table'>
      <thead>
        <tr>
          <th>Date</th>
          <th>Event</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event, index) => (
          <tr key={index}>
            <td>{event.date}</td>
            <td>{event.summary[1]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Shows;
