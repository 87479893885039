import React, { useEffect, useState } from 'react';

const NextShow = () => {
  const [nextEvent, setNextEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('https://oksure.com/nodejs-googlecal/api/events')
      .then(response => response.json())
      .then(data => {
        const filteredEvents = data.filter(event => event.summary[0] === 'The Hanky Panks');
        if (filteredEvents.length > 0) {
          setNextEvent(filteredEvents[0]);
        }
        setIsLoading(false);
      })
      .catch(error => console.error('Error fetching events:', error));
  }, []);

  if (isLoading) {
    return 'Loading next show; please wait...';
  }

  if (!nextEvent) {
    return 'No upcoming shows found for The Hanky Panks.';
  }

  return (
    <div style={{textAlign: 'center', border: '2px solid #dddddd', padding: '10px', backgroundColor: '#999999', color: 'black'}}>
      Next show: &nbsp; <b>{nextEvent.date}</b> at <b>{nextEvent.summary[1]}</b>
    </div>
  );
};

export default NextShow;
