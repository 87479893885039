import React from "react";
import YouTube from "react-youtube";
import "./YouTubePlayer.css"; // Make sure to import the CSS file

const YouTubePlayer = () => {
  const videoId = "pnDMn0UoQ6s";

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className="youtube-player">
      <YouTube videoId={videoId} opts={opts} />
    </div>
  );
};

export default YouTubePlayer;
