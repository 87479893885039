import React, { useState } from "react";
import "./App.css";
import Shows from "./components/Shows";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  const [selectedMenu, setSelectedMenu] = useState("home");

  const renderContent = () => {
    switch (selectedMenu) {
      case "home":
        return <Home />;
      case "shows":
        return <Shows />;
      case "contact":
        return <Contact />;
      default:
        return <Home />;
    }
  };

  return (
    <div className="app">
      <img
        src={`${process.env.PUBLIC_URL}/images/logo-transparent.png`}
        className="logo"
        alt="logo"
      />
      <a
        href="https://www.facebook.com/thehankypanks"
        className="facebook-link"
      >
        Follow <b>The Hanky Panks</b> on Facebook!
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
          alt="Facebook logo"
          className="facebook-logo"
        />
      </a>
      <img
        src={`${process.env.PUBLIC_URL}/images/trillions-kick.png`}
        className="trillions-kick"
        alt="trillions kick"
      />
      <div className="menu">
        <span
          className={`menu-item ${selectedMenu === "home" ? "selected" : ""}`}
          onClick={() => setSelectedMenu("home")}
        >
          Home
        </span>
        <span
          className={`menu-item ${selectedMenu === "shows" ? "selected" : ""}`}
          onClick={() => setSelectedMenu("shows")}
        >
          Shows
        </span>
        <span
          className={`menu-item ${
            selectedMenu === "contact" ? "selected" : ""
          }`}
          onClick={() => setSelectedMenu("contact")}
        >
          Contact
        </span>
      </div>
      <div className="content">{renderContent()}</div>
      <Footer />
    </div>
  );
}

export default App;
